import React from "react";
import "./PortfolioPanel.css"
import {useSelector} from "react-redux";
import {toEur} from "../../utils/tools";

export const PortfolioValue = () => {
  const wallet = useSelector(store => store.base.wallet);

  return (
    <>
      <div>
        <div className="portfolioValueCation">Portfolio Value - {wallet && toEur(wallet.portfolioValue)}</div>
        <div className="flexResponsive">
          <div className="portfolioPanelBox">
            <div className="portfolioCryptoBox portfolioPPC">
              <div className="flexResponsive">
                <div className="whiteIcon"/>
                <div>
                  <div className="portfolioValue">{wallet && toEur(wallet.ppcValue)}</div>
                  <div>PPC</div>
                </div>
              </div>
            </div>
            <div className="portfolioCryptoBox portfolioVCT">
              <div className="flexResponsive">
                <div className="whiteIcon"/>
                <div>
                  <div className="portfolioValue">{wallet && toEur(wallet.vctBalance * 0.4366)}</div>
                  <div>VCT</div>
                </div>
              </div>
            </div>

          </div>
          <div className="portfolioPanelBox">
            <div className="portfolioCryptoBox portfolioGOV">
              <div className="flexResponsive">
                <div className="whiteIcon"/>
                <div>
                  <div className="portfolioValue">{wallet && toEur(wallet.cgtBalance)}</div>
                  <div>GOV</div>
                </div>
              </div>
            </div>
            <div className="portfolioCryptoBox portfolioTBA">
              <div className="flexResponsive">
                <div className="whiteIcon"/>
                <div>
                  <div className="portfolioValue">{wallet && toEur(0)}</div>
                  <div>TBA</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}