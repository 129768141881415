import React, {useCallback, useEffect, useState} from "react";
import {PortfolioPanel} from "../../components/PortfolioPanel/PortfolioPanel";
import {PortfolioPrices} from "../../components/PortfolioPanel/PortfolioPrices";
import "./Account.css";
import {FilledTextField} from "../../components/LDTextField/FilledTextField";
import {useSelector} from "react-redux";
import {client} from "../../services/client";
import {notification} from "../../components/Notification";
import {Table, TableBody, TableCell, TableHead, TablePagination, TableRow} from "@material-ui/core";
import {isMobile} from "../../utils/checkDevice";
import {toEur} from "../../utils/tools";

export const Account = () => {
  const user = useSelector(store => store.base.user);
  const wallet = useSelector(store => store.base.wallet);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(1);
  const [loginHistory, setLoginHistory] = useState();

  const [headerFields, setHeaderFields] = React.useState([
    {name: 'ip', value: 'IP', direction: 'asc', sorted: true},
    {name: 'country', value: 'Country', direction: 'asc', sorted: true},
    {name: 'date', value: 'Date', direction: 'asc', sorted: true},
  ])

  const onChangePage = (_, newPage) => {
    setCurrentPage(newPage)
  }

  const getLoginHistory = useCallback(async () => {
    try {
      const response = await client.getLoginHistory({
        page: currentPage,
        size: rowsPerPage,
      });
      setLoginHistory(response.content);
      setTotal(response.totalElements);

    } catch (error) {
      notification.warning(error.message);
    } finally {
    }
  }, [currentPage, rowsPerPage]);

  useEffect(() => {
    getLoginHistory();
  }, [getLoginHistory]);

  return (
    <>
      {isMobile() ?
        <div className="mobileHeaderContainer">
          <div className="mobileHeader"/>
          <div className="mobileHeaderCircle"/>
        </div>
        : <PortfolioPanel rightComponent={<PortfolioPrices/>}/>
      }
      <div className={isMobile() ? "mobileContent" : "box flexResponsive"}>
        <div className={isMobile() ? "" : "accountPanelBox"}>
          {isMobile() ?
            <>
              <div>
                <div style={{textAlign: "center", fontSize: 22}}>
                  {user && user.firstName} {user && user.lastName}
                </div>
                <div className="greyLabel" style={{textAlign: "center", marginTop: 5}}>
                  {user && user.username}
                </div>
              </div>
              <div className="accountFunds">
                <h3 style={{marginBottom: 5}}>Funds</h3>
                <table>
                  <thead/>
                  <tbody>
                  <tr>
                    <td>Cash wallet</td>
                    <td style={{textAlign: "right"}}>{wallet && toEur(wallet.cashBalance)}</td>
                  </tr>
                  <tr>
                    <td>Trading wallet</td>
                    <td style={{textAlign: "right"}}>{wallet && toEur(wallet.tradingBalance)}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </>
            :
            <h3>Account information</h3>
          }

          <div className="accountPanelBox1">
            <div style={{marginBottom: 10}}>
              <FilledTextField
                id="firstName"
                name="firstName"
                type="text"
                autoComplete="off"
                variant="outlined"
                size="small"
                fullWidth
                value={user && user.firstName}
                disabled
              />
            </div>
            <div style={{marginBottom: 10}}>
              <FilledTextField
                id="lastName"
                name="lastName"
                type="text"
                autoComplete="off"
                variant="outlined"
                size="small"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={user && user.lastName}
                disabled
              />
            </div>
            <div style={{marginBottom: 10}}>
              <FilledTextField
                id="phone"
                name="phone"
                type="text"
                autoComplete="off"
                variant="outlined"
                size="small"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={user && user.phone}
                disabled
              />
            </div>
            <div style={{marginBottom: 10}}>
              <FilledTextField
                id="email"
                name="email"
                type="text"
                autoComplete="off"
                variant="outlined"
                size="small"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={user && user.email}
                disabled
              />
            </div>
            <div style={{marginBottom: 10}}>
              <FilledTextField
                id="country"
                name="country"
                type="text"
                autoComplete="off"
                variant="outlined"
                size="small"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={user && user.countryName}
                disabled
              />
            </div>
          </div>
        </div>
        <div className="accountPanelBox">
          <h3>Login history</h3>
          <Table size="small" style={isMobile() ? {marginTop: 5} : {marginTop: 30}}>
            <TableHead>
              <TableRow>
                {headerFields.map(f =>
                  <TableCell style={{borderBottom: "none"}} className="th" key={f.name}>{f.value}</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {loginHistory && loginHistory.map((loginHistory, index) => (
                <TableRow hover key={index}>
                  <TableCell style={{borderBottom: "none"}}>{loginHistory.ip}</TableCell>
                  <TableCell style={{borderBottom: "none"}}>{loginHistory.country}</TableCell>
                  <TableCell style={{borderBottom: "none"}}>{loginHistory.date}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={-1}
            page={currentPage}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onChangePage={onChangePage}
          />
        </div>
      </div>
    </>
  )
}