import { jwt } from './jwt';
import { api } from './api';

import { API_URLS } from './apiUrls';

const BASIC_CODE = process.env.REACT_APP_BASIC_CODE;

export class Client {

  //Users

  auth2fa = ({ email, password }) =>
    api.post(
      API_URLS.OAUTH.TOKEN,
      `grant_type=password&username=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`,
      {
        headers: {
          Authorization: `Basic ${BASIC_CODE}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );

  refreshToken = () =>
    api.post(
      API_URLS.OAUTH.TOKEN,
      `grant_type=refresh_token&refresh_token=${jwt.refreshToken}`,
      {
        headers: {
          Authorization: `Basic ${BASIC_CODE}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );

  //Users
  getCurrentUser = () => api.get(API_URLS.USERS.CURRENT);
  getLoginHistory = (params) => api.get(API_URLS.USERS.LOGIN_HISTORY, {params})
  getUserById = (id) => api.get(API_URLS.USERS.GET_BY_ID(id))

  //Wallets
  getUserWallet = () => api.get(API_URLS.WALLETS.GET_USER_WALLET);

  //Exchange Orders
  createExchangeOrder = (data) => api.post(API_URLS.EXCHANGE_ORDERS.CREATE, data)
  getOpenExchangeOrders = (params) => api.get(API_URLS.EXCHANGE_ORDERS.GET_OPEN, {params})
  getClosedExchangeOrders = (params) => api.get(API_URLS.EXCHANGE_ORDERS.GET_CLOSED, {params})
  getBookExchangeOrders = (params) => api.get(API_URLS.EXCHANGE_ORDERS.GET_BOOK, {params})
  getHistoryExchangeOrders = (params) => api.get(API_URLS.EXCHANGE_ORDERS.GET_HISTORY, {params})
  executeExchangeOrder = (id) => api.put(API_URLS.EXCHANGE_ORDERS.EXECUTE(id))
  cancelExchangeOrder = (id) => api.put(API_URLS.EXCHANGE_ORDERS.CANCEL(id))
  getPPCRate = () => api.get(API_URLS.EXCHANGE_ORDERS.GET_RATE);

  //Payments
  payOrder = (data) => api.post(API_URLS.PAYMENTS.PAY, data);

  //Currencies
  getRates = () => api.get(API_URLS.CURRENCIES.RATES);

  //SSE
  getStream = () => api.setStream(API_URLS.SSE.STREAM)
}

export const client = new Client();
