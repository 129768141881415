import React, {useCallback, useEffect, useState} from "react";
import './Orders.css';
import {isMobile} from "../../utils/checkDevice";
import {OrdersBlock} from "./OrdersBlock";
import {Deposits} from "./Deposits";
import {OrdersBook} from "./OrdersBook";
import {OpenOrders} from "./OpenOrders";
import {ClosedOrders} from "./ClosedOrders";
import {OrdersMobile} from "./OrdersMobile";


export const Orders = () => {

  return (
    <>
      {!isMobile() ?
        <>
          <div className="flexResponsive">
            <div className="flexResponsive ordersBox">
              <OrdersBlock/>
            </div>
            <div className="flexResponsive ordersBox">
              <Deposits/>
              <OrdersBook/>
            </div>

          </div>
          <div className="flexResponsive">
            <div className="flexResponsive ordersBox">
              <OpenOrders/>
            </div>
            <div className="flexResponsive ordersBox">
              <ClosedOrders/>
            </div>
          </div>
        </>
        :
        <OrdersMobile/>
      }
    </>
  )
}