import React, {useCallback, useEffect, useState} from "react"
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from "@material-ui/core";
import {notification} from "../../components/Notification";
import {client} from "../../services/client";
import {useSelector} from "react-redux";

export const History = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(1);
  const [orders, setOrders] = useState();
  const user = useSelector(store => store.base.user);

  const [headerFields, setHeaderFields] = React.useState([
    {name: 'amount', value: 'Amount', direction: 'asc', sorted: true},
    {name: 'price', value: 'Price', direction: 'asc', sorted: true},
    {name: 'type', value: 'Type', direction: 'asc', sorted: true},
    {name: 'status', value: 'Status', direction: 'asc', sorted: true},
    {name: 'executedAt', value: 'Status date', direction: 'asc', sorted: true},
  ])

  const getOrders = useCallback(async () => {
    try {
      const response = await client.getHistoryExchangeOrders({
        page: currentPage,
        size: rowsPerPage,
      });
      setOrders(response.content);
      setTotal(response.totalElements);

    } catch (error) {
      notification.warning(error.message);
    } finally {
    }
  }, [currentPage, rowsPerPage]);

  useEffect(() => {
    getOrders();
  }, [getOrders]);


  const onChangePage = (_, newPage) => {
    setCurrentPage(newPage)
  }

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(0);
  };

  const renderType = (order) => {
    const userId = user && user.id;
    if (order.type === "BUY") {
      if (userId === order.userId){
        return "BUY (BOUGHT)"
      } else {
        return "BUY (SOLD)"
      }
    }
    if (order.type === "SELL") {
      if (userId === order.userId){
        return "SELL (SOLD)"
      } else {
        return "SELL (BOUGHT)"
      }
    }
  }

  return (
    <>
      <div className="content">
        <div style={{marginTop: 30, marginBottom: 30}}>History</div>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                {headerFields.map(f =>
                  <TableCell key={f.name}>{f.value}</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {orders && orders.map((order, index) => (
                <TableRow style={{backgroundColor: index % 2 ? '#fff' : '#F1F1F1'}} hover key={index}>
                  <TableCell>{order.amount} PPC</TableCell>
                  <TableCell>{order.price.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'EUR'
                  })}</TableCell>
                  <TableCell>{renderType(order)}</TableCell>
                  <TableCell>{order.status}</TableCell>
                  <TableCell>{order.executedAt}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onChangePage={onChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
    </>
  );
}