import React, {useState} from "react";
import {useSelector} from "react-redux";
import "./PortfolioMobile.css";
import {renderChange, toEur} from "../../utils/tools";
import {ArrowDownward, ArrowUpward} from "@material-ui/icons";
import {Modal} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {notification} from "../../components/Notification";

export const PortfolioMobile = () => {
  const user = useSelector(store => store.base.user);
  const wallet = useSelector(store => store.base.wallet);
  const rates = useSelector(store => store.base.rates);
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [token, setToken] = useState("PPC");

  const handleOpenModal = (token) => {
    if (token === "PPC") {
      setToken(token)
      setOpenModal(true);
    } else {
      notification.warning("Trading " + token + " tokens is not available now");
    }
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleRedirect = (type) => {
    setOpenModal(false);
    history.push('/trade?token=' + token + '&type=' + type);
  }

  const modalBody = (
    <div className={'modal'}>
      <form>
        <div className="modalHeader">Choose sell or buy</div>
        <div className={'modalBody'}>
          <div className="pricesModalButtonContainer">
            <div style={{textAlign: "center"}} onClick={() => handleRedirect("Sell")}>
              <div>
                <div className="iconBuySell">
                  <ArrowUpward/>
                </div>
              </div>
              <div style={{marginTop: 5}}>Sell</div>
            </div>
            <div style={{textAlign: "center"}} onClick={() => {handleRedirect("Buy")}}>
              <div>
                <div className="iconBuySell">
                  <ArrowDownward/>
                </div>
              </div>
              <div style={{marginTop: 5}}>Buy</div>
            </div>
          </div>
        </div>
        <div className={'buttonContainer'}>
          <div className="cancelLink" onClick={handleCloseModal}>Cancel</div>
        </div>
      </form>
    </div>
  );

  return (
    <>
      <div className="mobileContent">
        <div>
          Hello, <b>{user && user.username}</b>
          <div className="portfolioMobileBox1">
            <div style={{fontSize: 14, fontWeight: 600, marginBottom: 10}}>Your current portfolio value</div>
            <div style={{fontSize: 22, fontWeight: 600}}>{wallet && toEur(wallet.portfolioValue)}</div>
          </div>
        </div>
        <div className="portfolioMobileBox2"/>
        <div style={{fontSize: 22, fontWeight: 600}}>Tokens</div>
        <div className="portfolioMobileTokensContainer">
          <div className="portfolioMobileTokenBox" onClick={() => handleOpenModal("PPC")}>
            <div className="ppcIconMobile"/>
            <div style={{fontSize: 19, fontWeight: 600}}>PPC</div>
            <div style={{fontSize: 12, color: "#8A8A8E", marginTop: 5}}>Pool participating cert</div>
            <div style={{marginTop: 10}}>{rates && renderChange(rates.PPC.change24h)}</div>
          </div>
          <div className="portfolioMobileTokenBox" onClick={() => handleOpenModal("GOV")}>
            <div className="govIconMobile"/>
            <div style={{fontSize: 19, fontWeight: 600}}>GOV</div>
            <div style={{fontSize: 12, color: "#8A8A8E", marginTop: 5}}>Governance tokens</div>
            <div style={{marginTop: 10}}>{rates && renderChange(0)}</div>
          </div>
          <div className="portfolioMobileTokenBox" onClick={() => handleOpenModal("VCT")}>
            <div className="vctIconMobile"/>
            <div style={{fontSize: 19, fontWeight: 600}}>VCT</div>
            <div style={{fontSize: 12, color: "#8A8A8E", marginTop: 5}}>VCT tokens</div>
            <div style={{marginTop: 10}}>{rates && renderChange(0)}</div>
          </div>
          <div className="portfolioMobileTokenBox" onClick={() => handleOpenModal("TBA")}>
            <div className="vctIconMobile"/>
            <div style={{fontSize: 19, fontWeight: 600}}>TBA</div>
            <div style={{fontSize: 12, color: "#8A8A8E", marginTop: 5}}>TBA tokens</div>
            <div style={{marginTop: 10}}>{rates && renderChange(0)}</div>
          </div>
        </div>
        <div className="mobileMargin"/>
      </div>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBody}
      </Modal>

    </>
  )
}