import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {TextField} from "@material-ui/core";

const useStylesReddit = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: '#F4F5F9',
    '&:hover': {
      backgroundColor: '#F4F5F9',
    },
    '&$focused': {
      backgroundColor: '#F4F5F9',
      borderColor: "#fff",
    },
  },
  focused: {},
}));

export const FilledTextField = (props) => {
  const classes = useStylesReddit();
  return <TextField InputProps={{ classes, disableUnderline: true }} {...props} />;
}