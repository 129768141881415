import React from 'react';
import './GuestLayout.css'

export const GuestLayout = ({children}) => {
  return (
    <>
      <div className={'guestBody'}>
        <div className="guestContent">{children}</div>
      </div>
    </>
  )
}