import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {FilledTextField} from "../../components/LDTextField/FilledTextField";
import {useSelector} from "react-redux";
import {notification} from "../../components/Notification";
import {client} from "../../services/client";
import {toEur} from "../../utils/tools";
import {BigGreenButton} from "../../components/LDButton/BigGreenButton";
import {BigRedButton} from "../../components/LDButton/BigRedButton";
import {Add, ArrowForwardIos} from "@material-ui/icons";

export const MobileTrade = () => {
  const history = useHistory();
  const token = new URLSearchParams(history.location.search).get('token');
  const type = new URLSearchParams(history.location.search).get('type');
  const wallet = useSelector(state => state.base.wallet);
  const rates = useSelector(state => state.base.rates);
  const [amount, setAmount] = useState("");
  const [price, setPrice] = useState("");
  const [fee, setFee] = useState(0);


  const handleChangeAmount = (e) => {
    let amount = e.target.value;
    amount = amount.toString().replace(",", ".");
    setAmount(amount);
    const price = amount * rates.PPC.eurRate;
    const fee = price * 0.05;
    rates && setPrice(price.toFixed(2));
    setFee(fee);
  }
  const handleChangePrice = (e) => {
    const price = e.target.value
    const fee = price * 0.05;
    setPrice(price);
    setFee(fee);
  }

  const handleDepositsClick = () => {
    history.push("/deposits");
  }

  const handleSubmit = async () => {
    if (amount <= 0) {
      notification.warning("Wrong amount");
    }
    if (price <= 0) {
      notification.warning("Wrong price");
    } else {
      try {
        await client.createExchangeOrder({
          amount: amount,
          price: price,
          type: type.toUpperCase(),
        });
        setAmount("");
        setPrice("");
        notification.success("Order created");
        history.push("/orders")
      } catch (e) {
        notification.warning(e.message || e.error);
      }
    }
  }


  return (
    <>
      <div className="mobileContent">
        <div style={{textAlign: "center", fontSize: 17, fontWeight: 600}}>
          {type} {token}
        </div>
        <div style={{display: "flex", marginTop: 30}}>
          <div className="mobileTradeAmount">
            <div>
              <div style={{marginBottom: 2}}>{token} amount</div>
              <div>
                <FilledTextField
                  id="amount"
                  name="amount"
                  type="text"
                  placeholder="0.00"
                  autoComplete="off"
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChangeAmount}
                  value={amount}
                />
              </div>
            </div>
          </div>
          <div className="mobileTradePrice">
            <div>
              <div style={{marginBottom: 2}}>{token} price</div>
              <div>
                <FilledTextField
                  id="price"
                  name="price"
                  type="text"
                  placeholder="0.00"
                  autoComplete="off"
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChangePrice}
                  value={price}
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{marginTop: 30}}>
          <table className="table">
            <thead/>
            <tbody>
            <tr>
              <td>Amount</td>
              <td style={{textAlign: "right", fontWeight: 600}}>{amount === "" ? 0 : amount} PPC</td>
            </tr>
            <tr>
              <td>Price</td>
              <td style={{textAlign: "right", fontWeight: 600}}>{price === "" ? toEur(0) : toEur(price)}</td>
            </tr>
            <tr>
              <td>{type.toUpperCase() === "BUY" ? "Buying fee 0%" : "Selling fee 5%"}</td>
              <td style={{
                textAlign: "right",
                fontWeight: 600
              }}>{type.toUpperCase() === "SELL" ? toEur(fee) : toEur(0)}</td>
            </tr>
            <tr>
              <td style={{fontWeight: 600}}>Total cost</td>
              <td style={{
                textAlign: "right",
                fontWeight: 600
              }}>{type.toUpperCase() === "SELL" ? toEur(+price - +fee) : toEur(price)}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div className="mobileDepositFundsContainer" onClick={handleDepositsClick}>
          <div className="mobileDepositFunds">
            <Add/>
          </div>
          <div style={{fontWeight: 600}}>Deposit funds</div>
          <div>
            <ArrowForwardIos/>
          </div>
        </div>
        <div style={{marginTop: 50}}>
          {type.toUpperCase() === "BUY" ?
            <BigGreenButton onClick={handleSubmit}>Buy now</BigGreenButton>
            :
            <BigRedButton onClick={handleSubmit}>Sell now</BigRedButton>
          }
        </div>
      </div>
    </>
  )
}