import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {DashboardLayout} from "./components/Layout/DashboardLayout/DashboardLayout";
import {Auth} from "./pages/auth/Auth";
import {GuestLayout} from "./components/Layout/GuestLayout/GuestLayout";
import {Orders} from "./pages/orders/Orders";
import {History} from "./pages/history/History";
import {Portfolio} from "./pages/portfolio/Portfolio";
import {Prices} from "./pages/prices/Prices";
import {Account} from "./pages/account/Account";
import {OpenOrders} from "./pages/orders/OpenOrders";
import {OpenOrdersMobile} from "./pages/orders/OpenOrdersMobile";
import {ClosedOrdersMobile} from "./pages/orders/ClosedOrdersMobile";
import {PricesDetailsMobile} from "./pages/prices/PricesDetailsMobile";
import {MobileTrade} from "./pages/orders/MobileTrade";
import {Deposits} from "./pages/orders/Deposits";

export const useRoutes = (isAuth, userStatus) => {
  if (isAuth) {
    return (
      <DashboardLayout>
        <Route path="/" exact={true}>
          <Orders/>
        </Route>
        <Route path="/orders" exact={true}>
          <Orders/>
        </Route>
        <Route path="/orders/open" exact={true}>
          <OpenOrdersMobile/>
        </Route>
        <Route path="/orders/closed" exact={true}>
          <ClosedOrdersMobile/>
        </Route>
        <Route path="/history" exact={true}>
          <History/>
        </Route>
        <Route path="/portfolio" exact={true}>
          <Portfolio/>
        </Route>
        <Route path="/prices" exact={true}>
          <Prices/>
        </Route>
        <Route path="/details">
          <PricesDetailsMobile/>
        </Route>
        <Route path="/trade">
          <MobileTrade/>
        </Route>
        <Route path="/deposits">
          <Deposits/>
        </Route>
        <Route path="/account" exact={true}>
          <Account/>
        </Route>
      </DashboardLayout>
    )
  }
  return (
    <GuestLayout>
      <Switch>
        <Route path="/login">
          <Auth/>
        </Route>
        <Redirect to={'/login'}/>
      </Switch>
    </GuestLayout>
  )
}