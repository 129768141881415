import React from "react";
import {useSelector} from "react-redux";
import "../../index.css";
import "./Prices.css";
import {toEur} from "../../utils/tools";
import {useHistory} from "react-router-dom";

export const PricesMobile = () => {
  const wallet = useSelector(store => store.base.wallet);
  const history = useHistory();

  const handleRedirect = (token) => {
    history.push('/details?token=' + token);
  }

  return (
    <>
      <div className="mobileContent">
        <div style={{textAlign: "center", fontSize: 17, fontWeight: 600, marginBottom: 30}}>Market prices</div>
        <div className="mobilePriceLine" onClick={() => handleRedirect("PPC")}>
          <div style={{display: "flex"}}>
            <div className="mobileCurrencyIcon"/>
            <div>
              <div style={{fontSize: 16}}>{wallet && wallet.ppcBalance} PPC</div>
              <div style={{fontSize: 12, color: "#8A8A8E", marginTop: 5}}>Pool participating certificate</div>
            </div>
          </div>
          <div style={{fontSize: 16}}>{wallet && toEur(wallet.ppcValue)}</div>
        </div>
        <div className="mobilePriceLine" onClick={() => handleRedirect("GOV")}>
          <div style={{display: "flex"}}>
            <div className="mobileCurrencyIcon"/>
            <div>
              <div style={{fontSize: 16}}>{wallet && wallet.cgtBalance} GOV</div>
              <div style={{fontSize: 12, color: "#8A8A8E", marginTop: 5}}>Governance tokens</div>
            </div>
          </div>
          <div style={{fontSize: 16}}>{wallet && toEur(wallet.cgtBalance)}</div>
        </div>
        <div className="mobilePriceLine" onClick={() => handleRedirect("VCT")}>
          <div style={{display: "flex"}}>
            <div className="mobileCurrencyIcon"/>
            <div>
              <div style={{fontSize: 16}}>{wallet && wallet.vctBalance} VCT</div>
              <div style={{fontSize: 12, color: "#8A8A8E", marginTop: 5}}>VCT tokens</div>
            </div>
          </div>
          <div style={{fontSize: 16}}>{wallet && toEur(wallet.vctBalance * 0.4366)}</div>
        </div>
        <div className="mobilePriceLine" onClick={() => handleRedirect("TBA")}>
          <div style={{display: "flex"}}>
            <div className="mobileCurrencyIcon"/>
            <div>
              <div style={{fontSize: 16}}>{0} TBA</div>
              <div style={{fontSize: 12, color: "#8A8A8E", marginTop: 5}}>TBA tokens</div>
            </div>
          </div>
          <div style={{fontSize: 16}}>{wallet && toEur(0)}</div>
        </div>
      </div>
    </>
  )
}