import React, {useState} from "react";
import './Orders.css';
import {Tab, Tabs} from "@material-ui/core";
import {Link} from "react-router-dom";
import {OrdersBook} from "./OrdersBook";
import {OpenOrders} from "./OpenOrders";

export const OpenOrdersMobile = () => {
  const [value, setValue] = useState(1);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div>
        <div className="mobileHeaderContainer">
          <div className="mobileHeader"/>
          <div className="mobileHeaderCircle"/>
        </div>
        <div className="mobileContent">
          <div style={{textAlign: "center"}}>
            <div className="mobileCaption">PPC Orders</div>
            <div className="greyLabel">
              Pool Participating Certificate
            </div>
            <div style={{textAlign: "center", margin: "0 auto", marginTop: 10, marginBottom: 10}}>
              <Tabs
                centered
                value={value}
                indicatorColor="secondary"
                textColor="primary"
                onChange={handleChange}
                TabIndicatorProps={{style: {background:'#FCAD2A', color: "#FCAD2A"}}}
              >
                <Tab label="Order book" component={Link} to={'/orders'}/>
                <Tab label="Open" component={Link} to={'/orders/open'}/>
                <Tab label="Closed" component={Link} to={'/orders/closed'}/>
              </Tabs>
            </div>
          </div>
          <OpenOrders/>
        </div>
      </div>
    </>
  )
}