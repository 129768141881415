import React, {useCallback, useEffect, useState} from "react";
import {
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow, Tooltip
} from "@material-ui/core";
import {client} from "../../services/client";
import {notification} from "../../components/Notification";
import {toEur} from "../../utils/tools";
import {Cancel} from "@material-ui/icons";
import {isMobile} from "../../utils/checkDevice";

export const OpenOrders = () => {
  const [currency, setCurrency] = useState("PPC");
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [orders, setOrders] = useState();

  const [headerFields, setHeaderFields] = isMobile() ? React.useState([
    {name: 'marketSize', value: 'Market size', direction: 'asc', sorted: true},
    {name: 'time', value: 'Time', direction: 'asc', sorted: true},
    {name: 'eur', value: 'Eur', direction: 'asc', sorted: true},
    {name: 'actions', value: '', direction: 'asc', sorted: true},
  ]) : React.useState([
    {name: 'date', value: 'Date created', direction: 'asc', sorted: true},
    {name: 'amount', value: 'Amount', direction: 'asc', sorted: true},
    {name: 'type', value: 'Type', direction: 'asc', sorted: true},
    {name: 'price', value: 'Price', direction: 'asc', sorted: true},
    {name: 'totalAmount', value: 'Total', direction: 'asc', sorted: true},
    {name: 'actions', value: '', direction: 'asc', sorted: true},
  ])

  const onChangePage = (_, newPage) => {
    setCurrentPage(newPage)
  }

  const handleChangeCurrency = (e) => {
    setCurrency(e.target.value)
  }

  const getOrders = useCallback(async () => {
    try {
      const response = await client.getOpenExchangeOrders({
        page: currentPage,
        size: rowsPerPage,
      });
      setOrders(response.content);
      setTotal(response.totalElements);

    } catch (error) {
      notification.warning(error.message);
    } finally {
    }
  }, [currentPage, rowsPerPage]);

  useEffect(() => {
    getOrders();
  }, [getOrders]);

  const renderAmount = (order) => {
    if (order.type === "SELL") {
      return <span style={{color: "red"}}>{order.remain}</span>
    }
    if (order.type === "BUY") {
      return <span style={{color: "green"}}>{order.remain}</span>
    }
  }

  const renderActions = (order) => {
    return (
      <Tooltip title="Cancel" aria-label="cancel">
        <Cancel className="actionIcon red" onClick={() => cancelOrder(order.id)}/>
      </Tooltip>
    )
  }

  const cancelOrder = async (id) => {
    try {
      await client.cancelExchangeOrder(id);
      notification.success("Order " + id + " canceled");
      getOrders();
    } catch (e) {
      notification.warning(e.message || e.error);
    }
  }

  return (
    <div className="box" style={{width: "100%", alignItems: "center"}}>
      {!isMobile() &&
      <div className="flexResponsive" style={{justifyContent: "space-between", marginBottom: 30}}>
        <div style={{display: "flex", alignItems: "center"}}><h2>Open orders</h2></div>
        <FormControl variant="outlined" size="small" style={{width: "50%"}}>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={currency && currency}
            onChange={handleChangeCurrency}
            style={{width: "100%"}}
          >
            <MenuItem key={0} value={"PPC"}>
              <div className="flexResponsive centerVertical">
                <div className="ppcIcon"/>
                PPC
              </div>
            </MenuItem>
          </Select>
        </FormControl>
      </div>
      }
      <Table size="small">
        <TableHead>
          <TableRow>
            {headerFields.map(f =>
              <TableCell style={{borderBottom: "none"}} className="th" key={f.name}>{f.value}</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {orders && orders.map((order, index) => (
            <TableRow hover key={index}>
              {isMobile() ?
                <>
                  <TableCell style={{borderBottom: "none"}}>{renderAmount(order)}</TableCell>
                  <TableCell style={{borderBottom: "none"}}>{order.createdAt}</TableCell>
                  <TableCell
                    style={{borderBottom: "none"}}>{toEur(order.price / order.amount * order.remain)}</TableCell>
                  <TableCell style={{borderBottom: "none", paddingTop: 10}}>{renderActions(order)}</TableCell>
                </> : <>
                  <TableCell style={{borderBottom: "none", verticalAlign: "center"}}>{order.createdAt}</TableCell>
                  <TableCell style={{borderBottom: "none"}}>{renderAmount(order)}</TableCell>
                  <TableCell style={{borderBottom: "none"}}>{order.type}</TableCell>
                  <TableCell style={{borderBottom: "none"}}>{toEur(order.pricePerCoin)}</TableCell>
                  <TableCell
                    style={{borderBottom: "none"}}>{toEur(order.price / order.amount * order.remain)}</TableCell>
                  <TableCell style={{borderBottom: "none", paddingTop: 10}}>{renderActions(order)}</TableCell>
                </>}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {total > 10 &&
      <TablePagination
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={-1}
        page={currentPage}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onChangePage={onChangePage}
      />
      }
      {isMobile() && <div className="mobileMargin"/>}
    </div>
  )
}