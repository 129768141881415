import React, {useCallback, useEffect, useState} from "react";
import {FormControl, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {client} from "../../services/client";
import {notification} from "../../components/Notification";
import {toEur} from "../../utils/tools";
import {useSelector} from "react-redux";
import {isMobile} from "../../utils/checkDevice";

export const OrdersBook = () => {
  const [currency, setCurrency] = useState("PPC");
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const orders = useSelector(store => store.base.orderBook);
  const boxClassName = isMobile() ? "box" : "box ordersBox";

  const [headerFields, setHeaderFields] = isMobile() ? React.useState([
      {name: 'marketSize', value: 'Market size', direction: 'asc', sorted: true},
      {name: 'time', value: 'Time', direction: 'asc', sorted: true},
      {name: 'eur', value: 'Eur', direction: 'asc', sorted: true},
    ]) :
    React.useState([
      {name: 'price', value: 'Price', direction: 'asc', sorted: true},
      {name: 'amount', value: 'Amount', direction: 'asc', sorted: true},
      {name: 'totalAmount', value: 'Total', direction: 'asc', sorted: true},
    ]);

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(0);
  };

  const handleChangeCurrency = (e) => {
    setCurrency(e.target.value)
  }

  const getOrders = useCallback(async () => {
    try {
      const response = await client.getBookExchangeOrders({
        page: currentPage,
        size: rowsPerPage,
      });
      // setOrders(response.content);
      // setTotal(response.totalElements);
      let t = [];
      const r = response.content.map((e, i) => {
        console.log(e.id);
        if (e.id !== 225) {
          t.push(e)
        }
      });
      t.map((e, i) => {
        console.log(e)
      })
      // setOrders(t);
    } catch (error) {
      notification.warning(error.message);
    } finally {
    }
  }, []);


  const renderAmount = (order) => {
    if (order.type === "SELL") {
      return <span className="colorRed">{order.remain}</span>
    }
    if (order.type === "BUY") {
      return <span className="colorGreen">{order.remain}</span>
    }
  }

  return (
    <div className={boxClassName}>
      {!isMobile() && (
        <>
          <h2>Order book</h2>
          <FormControl variant="outlined" size="small" style={{marginTop: 15, width: "100%"}}>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={currency && currency}
              onChange={handleChangeCurrency}
              style={{width: "100%"}}
            >
              <MenuItem key={0} value={"PPC"}>
                <div className="flexResponsive centerVertical">
                  <div className="ppcIcon"/>
                  PPC
                </div>
              </MenuItem>
            </Select>
          </FormControl>
        </>
      )}
      <Table size="small">
        <TableHead>
          <TableRow>
            {headerFields.map(f =>
              <TableCell style={{borderBottom: "none"}} className="th" key={f.name}>{f.value}</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {orders && orders.map((order, index) => (
            <TableRow hover key={index}>
              {isMobile() ?
                <>
                  <TableCell style={{borderBottom: "none"}}>{renderAmount(order)}</TableCell>
                  <TableCell style={{borderBottom: "none"}}>{order.createdAt}</TableCell>
                  <TableCell
                    style={{borderBottom: "none"}}>{toEur(order.price / order.amount * order.remain)}</TableCell>
                </> :
                <>
                  <TableCell style={{borderBottom: "none"}}>{toEur(order.pricePerCoin)}</TableCell>
                  <TableCell style={{borderBottom: "none"}}>{renderAmount(order)}</TableCell>
                  <TableCell
                    style={{borderBottom: "none"}}>{toEur(order.price / order.amount * order.remain)}</TableCell>
                </>
              }
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {isMobile() && <div className="mobileMargin"/>}
    </div>
  )
}