import React, {useEffect, useCallback, useState} from 'react';
import './DashboardLayout.css'

import {useDispatch, useSelector} from "react-redux";
import {Link, NavLink, useHistory} from "react-router-dom";
import {client} from "../../../services/client";
import {logout, setOrderBook, setRate, setRates, setUser, setWallet} from "../../../store/actions/actions";
import {
  AppBar, BottomNavigation, BottomNavigationAction,
  Button, Card, CardContent,
  Divider,
  Drawer,
  IconButton,
  List,
  Paper,
  Tab,
  Tabs,
  Toolbar, Tooltip,
  Typography, useTheme
} from "@material-ui/core";
import {
  AccountBalanceWallet,
  Assignment,
  BarChart,
  BusinessCenter,
  ExitToApp,
  History,
  ImportExport
} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import {isMobile} from "../../../utils/checkDevice";

import DashboardIcon from '@material-ui/icons/Dashboard';
import PersonIcon from '@material-ui/icons/Person';
import TimelineIcon from '@material-ui/icons/Timeline';
import {notification} from "../../Notification";
import clsx from "clsx";

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export const DashboardLayout = ({children}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = useState(true);
  const user = useSelector(store => store.base.user);
  const wallet = useSelector(store => store.base.wallet);
  const rates = useSelector(store => store.base.rates);
  const orderBook = useSelector(store => store.base.orderBook);
  const [value, setValue] = useState(0);
  const [walletLoaded, setWalletLoaded] = useState(false);
  const [ratesLoaded, setRatesLoaded] = useState(false);
  const [orderBookLoaded, setOrderBookLoaded] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleLogout = () => {
    dispatch(logout());
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const fetchCurrentUser = useCallback(async () => {
    if (user === null) {
      const user = await client.getCurrentUser();
      dispatch(setUser(user));
    }
  }, [user, history]);

  const fetchUserWallet = useCallback(async () => {
    if (wallet === null) {
      const wallet = await client.getUserWallet();
      dispatch(setWallet(wallet));
      setWalletLoaded(true);
    }
  }, []);

  const getRates = useCallback(async () => {
    try {
      const rates = await client.getRates();
      dispatch(setRates(rates));
      setRatesLoaded(true);
    } catch (e) {
      notification.warning(e.message);
    }
  }, [history]);

  const getOrderBook = useCallback(async () => {
    try {
      const orderBook = await client.getBookExchangeOrders({
        page: 0,
        size: 7
      });
      dispatch(setOrderBook(orderBook.content));
      setOrderBookLoaded(true);
    } catch (e) {
      notification.warning(e.message);
    }
  }, [history]);


  useEffect(() => {
    fetchCurrentUser();
    fetchUserWallet();
    getRates();
    getOrderBook();
  }, []);

  useEffect(() => {
    if (walletLoaded && ratesLoaded && orderBookLoaded) {
      const sse = client.getStream();
      sse.onmessage = function (event) {
        if (event.data !== "") {
          const msg = JSON.parse(event.data);
          console.log(msg)
          switch (msg.type) {
            case "CASH_BALANCE":
              dispatch(setWallet({...wallet, cashBalance: +msg.value}));
              wallet.cashBalance = +msg.value;
              break;
            case "TRADING_BALANCE":
              dispatch(setWallet({...wallet, tradingBalance: +msg.value}));
              wallet.tradingBalance = +msg.value;
              break;
            case "PPC_BALANCE":
              dispatch(setWallet({...wallet, ppcBalance: +msg.value}));
              break;
            // case "BTC_RATE":
            //   dispatch(setRates({...rates, BTC: +msg.value}));
            //   break;
            // case "ETH_RATE":
            //   dispatch(setRates({...rates, ETH: +msg.value}));
            //   break;
            // case "USDT_RATE":
            //   dispatch(setRates({...rates, USDT: +msg.value}));
            //   break;
            // case "PPC_RATE":
            //   dispatch(setRates({...rates, PPC: +msg.value}));
            //   break;
            case "ORDER_CREATED":
              console.log("ORDER_CREATED", +msg.value)
              // let t = [];
              // orderBook.map((e, i) => {
              //   console.log(e.id);
              //   if (e.id !== +msg.value) {
              //     t.push(e)
              //   }
              // });
              // t.map((e, i) => {
              //   console.log(e)
              // })
              break;
          }
        }
      }
    }
  }, [walletLoaded, ratesLoaded, orderBookLoaded])


  const links = [
    {to: "/orders", text: "Orders", icon: <Assignment/>},
    {to: "/portfolio", text: "Portfolio", icon: <BusinessCenter/>},
    {to: "/prices", text: "Prices", icon: <BarChart/>},
    {to: "/account", text: "Account", icon: <AccountBalanceWallet/>},
    {to: "/logout", text: "Logout", icon: <ExitToApp/>},
  ];

  const handleOrdersClick = () => {
    history.push('/orders');
  }
  const handlePortfolioClick = () => {
    history.push('/portfolio');
  }
  const handlePricesClick = () => {
    history.push('/prices');
  }
  const handleAccountClick = () => {
    history.push('/account');
  }

  const handleRedirect = (e) => {
    if (e.to === "/logout") {
      handleLogout();
    } else {
      history.push(e.to)
    }
  }


  return (
    <>
      {!isMobile() ?
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: drawerOpen,
            [classes.drawerClose]: !drawerOpen,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: drawerOpen,
              [classes.drawerClose]: !drawerOpen,
            }),
          }}
        >
          <div className={classes.toolbar}>
            {drawerOpen && <div className="logoV1Small"/>}
            <IconButton onClick={drawerOpen ? handleDrawerClose : handleDrawerOpen}
                        style={{visibility: "hidden", position: "absolute", top: -12, right: -12}}>
              {drawerOpen ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
            </IconButton>
          </div>
          <List>
            {links.map((e, index) => (
              <ListItem button key={e.to} onClick={() => handleRedirect(e)}>
                <ListItemIcon style={{marginLeft: "9px"}}>{e.icon}</ListItemIcon>
                <ListItemText primary={e.text}/>
              </ListItem>
            ))}
          </List>
        </Drawer>
        :
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          showLabels
          className={'mainMenuMobile'}
        >
          <BottomNavigationAction onClick={handleOrdersClick} label="Orders" icon={<Assignment/>}/>
          <BottomNavigationAction onClick={handlePortfolioClick} label="Portfolio" icon={<BusinessCenter/>}/>
          <BottomNavigationAction onClick={handlePricesClick} label="Prices" icon={<BarChart/>}/>
          <BottomNavigationAction onClick={handleAccountClick} label="Account" icon={<AccountBalanceWallet/>}/>
          <BottomNavigationAction onClick={handleLogout} label="Logout" icon={<ExitToApp/>}/>
        </BottomNavigation>
      }

      <div className="pageContent">
        {children}
      </div>
    </>
  )
}