export const API_URLS = {
  OAUTH: {
    TOKEN: 'oauth/token',
  },
  USERS: {
    CURRENT: 'users/current',
    LOGIN_HISTORY: 'loginHistory',
    GET_BY_ID: (id) => `search/users/${id}`,
  },
  WALLETS: {
    GET_USER_WALLET: 'wallets/user',
  },
  EXCHANGE_ORDERS: {
    CREATE: 'exchange/orders',
    GET_OPEN: 'exchange/orders/open',
    GET_CLOSED: 'exchange/orders/closed',
    GET_BOOK: 'exchange/orders/book',
    GET_HISTORY: 'exchange/orders/history',
    GET_RATE: 'exchange/rate/ppc/eur',
    EXECUTE: (id) => `exchange/orders/${id}/execute`,
    CANCEL: (id) => `exchange/orders/${id}/cancel`,
  },
  PAYMENTS: {
    PAY: 'orders/pay',
  },
  SECURE: {
    EXIST: 'secure/2fa/exist',
    CLEAR: 'secure/2fa/clear',
  },
  CURRENCIES: {
    RATES: 'currencies/rates'
  },
  SSE: {
    STREAM: 'sse/exchange/stream'
  },
};
