import React from "react";
import {withStyles} from "@material-ui/core/styles";
import {Button} from "@material-ui/core";

export const GreenButton = withStyles(() => ({
  root: {
    color: "white",
    backgroundColor: "#1DAB5F",
    '&:hover': {
      backgroundColor: "#1DAB5F",
    },
    borderRadius: 8,
    minWidth: 150,
    height: 45
  },
}))(Button);