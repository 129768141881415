import React, {useState} from "react";
import {toEur} from "../../utils/tools";
import {Box, Checkbox, FormControl, FormControlLabel, MenuItem, Modal, Select} from "@material-ui/core";
import {FilledTextField} from "../../components/LDTextField/FilledTextField";
import {GreenButton} from "../../components/LDButton/GreenButton";
import {RedButton} from "../../components/LDButton/RedButton";
import {useSelector} from "react-redux";
import {client} from "../../services/client";
import {notification} from "../../components/Notification";
import {BigOrangeButton} from "../../components/LDButton/BigOrangeButton";

export const OrdersBlock = () => {
  const wallet = useSelector(state => state.base.wallet);
  const rates = useSelector(state => state.base.rates);
  const [openModal, setOpenModal] = useState(false);
  const [approveTerms, setApproveTerms] = useState(false);
  const [currency, setCurrency] = useState("PPC");
  const [orderType, setOrderType] = useState();
  const [amount, setAmount] = useState("");
  const [price, setPrice] = useState("");
  const [fee, setFee] = useState(0);

  const handleOpenModal = (type) => {
    if (approveTerms === false) {
      notification.warning("You must approve to the terms and conditions");
    } else {
      setOrderType(type);
      setOpenModal(true);
    }
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleChangeAmount = (e) => {
    let amount = e.target.value;
    amount = amount.toString().replace(",", ".");
    setAmount(amount);
    const price = amount * rates.PPC.eurRate;
    const fee = price * 0.05;
    rates && setPrice(price.toFixed(2));
    setFee(fee);
  }
  const handleChangePrice = (e) => {
    const price = e.target.value
    const fee = price * 0.05;
    setPrice(price);
    setFee(fee);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await client.createExchangeOrder({
        amount: amount,
        price: price,
        type: orderType,
      });
      setAmount("");
      setPrice("");
      setApproveTerms(false);
      notification.success("Order created");
      handleCloseModal();
    } catch (e) {
      notification.warning(e.message || e.error);
      handleCloseModal();
    }
  }

  const handleChangeCurrency = (e) => {
    setCurrency(e.target.value)
  }

  const handleChangeApproveTerms = (e) => {
    setApproveTerms(e.target.checked);
  }

  const modalBody = (
    <div className={'modal'}>
      <form onSubmit={handleSubmit}>
        <div className="modalHeader">Are you sure you want to create a {orderType} order?</div>
        <div className={'modalBody'}>

          <table className="table">
            <thead/>
            <tbody>
            <tr>
              <td>Amount</td>
              <td>{amount} PPC</td>
            </tr>
            <tr>
              <td>Price</td>
              <td>{toEur(price)}</td>
            </tr>
            <tr>
              <td>Fee</td>
              <td>{orderType === "SELL" ? toEur(fee) : toEur(0)}</td>
            </tr>
            <tr>
              <td>Total</td>
              <td>{orderType === "SELL" ? toEur(+price - +fee) : toEur(price)}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div className={'buttonContainer'}>
          <BigOrangeButton
            type="submit"
          >
            Confirm
          </BigOrangeButton>
          <div className="cancelLink" onClick={handleCloseModal}>Cancel</div>
        </div>
      </form>
    </div>
  );


  return (
    <>
      <div className="box">
        <div className="boxRow">
          <h2>Orders</h2>
          <div>Cash wallet: {wallet && toEur(wallet.cashBalance)}</div>
          <div>Trading wallet: {wallet && toEur(wallet.tradingBalance)}</div>
        </div>
        <div className="boxRow">
          <FormControl variant="outlined" size="small" style={{minWidth: "auto", marginRight: 10}}>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={currency && currency}
              onChange={handleChangeCurrency}
              style={{width: 120}}
            >
              <MenuItem key={0} value={"PPC"}>
                <div className="flexResponsive centerVertical">
                  <div className="ppcIcon"/>
                  PPC
                </div>
              </MenuItem>
            </Select>
          </FormControl>
          <div className="boxPrice">
            <div className="greyLabel">Price</div>
            <div className="flexResponsive">
              <div className="fontWeight600">{rates && toEur(rates.PPC.eurRate)}</div>
              {rates && rates.PPC.change24h >= 0 && (
                <div style={{marginLeft: 5}} className="greenLabel"> +{toEur(rates.PPC.change24h)}</div>)}
              {rates && rates.PPC.change24h < 0 && (
                <div style={{marginLeft: 5}} className="redLabel"> -{toEur(rates.PPC.change24h)}</div>)}
            </div>
          </div>
          <div className="boxPrice withBorders">
            <div className="greyLabel">Rate</div>
            <div className="fontWeight600">+{rates && toEur(rates.PPC.change24h)}/24h</div>
          </div>
          <div className="boxPrice">
            <div className="greyLabel">Volume</div>
            <div className="fontWeight600">{rates && toEur(rates.PPC.volume24h)}</div>
          </div>
        </div>
        <form>
          <div className="boxRow" style={{paddingLeft: 30}}>
            <table>
              <tbody>
              <tr>
                <td style={{width: 100}}>Amount</td>
                <td>
                  <FilledTextField
                    id="amount"
                    name="amount"
                    type="text"
                    placeholder="0.00"
                    autoComplete="off"
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChangeAmount}
                    value={amount}
                  />
                </td>
              </tr>
              <tr>
                <td>Total price</td>
                <td>
                  <FilledTextField
                    id="price"
                    name="price"
                    type="text"
                    placeholder="0.00"
                    autoComplete="off"
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChangePrice}
                    value={price}
                  />
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div className="boxRow">
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={approveTerms}
                    onChange={handleChangeApproveTerms}
                    name="approveTerms"
                    color="primary"
                  />
                }
                label={<Box component="div" fontSize={12}>
                  I approve to the terms and conditions that apply to this order.
                </Box>
                }
              />
            </div>
            <div>
              <GreenButton onClick={(e) => {
                e.preventDefault();
                handleOpenModal("BUY")
              }} type="submit">Buy</GreenButton>
            </div>
            <div style={{marginLeft: 20}}>
              <RedButton onClick={() => handleOpenModal("SELL")}>Sell</RedButton>
            </div>
          </div>
        </form>
      </div>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBody}
      </Modal>
    </>
  )
}