import React from 'react';
import {notification} from "../components/Notification";

export const getNextOptionIndex = (currentIndex, array, toNext) => {
  let newIndex = 0;

  if (array.length === 0) {
    return newIndex;
  }

  if (toNext) {
    const isLast = currentIndex >= array.length - 1;
    newIndex = isLast ? 0 : currentIndex + 1;
  } else {
    const isFirst = currentIndex <= 0;
    newIndex = isFirst ? array.length - 1 : currentIndex - 1;
  }

  return newIndex;
};

export const IfNilSwapToEmptyString = (value) => (isNil(value) ? '' : value);

export const sortObjectsArray = (
  byField,
  array,
  isDesc,
) => {
  const makeSortFunc = (item1, item2) => {
    const value1 = IfNilSwapToEmptyString(item1[byField]);
    const value2 = IfNilSwapToEmptyString(item2[byField]);
    if (value1 < value2) {
      return isDesc ? -1 : 1;
    }
    if (value1 > value2) {
      return isDesc ? 1 : -1;
    }
    return 0;
  };

  return [...array].sort(makeSortFunc);
};

const isUndefined = (value) => value === undefined;
const isNull = (value) => value === null;
export const isNil = (value) => isNull(value) || isUndefined(value);

// export const IfNilSwapToEmptyString = (value) => (isNil(value) ? '' : value);
// export const getKeyByValue = (obj, value) =>
//   Object.entries(obj)
//     .filter(i => i[1] === value)
//     .flatMap(i => i)[0];

export const convertToStringTime = (milliseconds) => {
  const minutes = Math.floor(milliseconds / 1000 / 60);
  const seconds = (milliseconds / 1000) % 60;
  const stringMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const stringSeconds = seconds < 10 ? `0${seconds}` : seconds;
  return `${stringMinutes} min ${stringSeconds} sec`;
};

export const noop = () => null;

export const copyHandler = (value) => {
  const el = document.createElement('input');
  el.value = value;
  el.style.left = '-5000px';
  el.style.right = '-5000px';
  el.style.position = '-5000px';
  el.style.zIndex = '-5000px';
  document.body.appendChild(el);
  el.select();
  el.setSelectionRange(0, 99999);
  if (document.execCommand('copy')) {
    notification.success('Copied to clipboard');
  }
  document.body.removeChild(el);
};

export const toggleNoScrollBody = () => {
  document.querySelector('body')?.classList.toggle('noscroll');
};

export const searchParamsToObject = (searchParams) => {
  const params = {}
  for (const [key, value] of searchParams.entries()) {
    params[key] = value
  }
  return params
}

export const toEur = (amount) => {
  return amount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'EUR'
  })
}

export const renderChange = (amount) => {
  return amount >= 0 ? <span className="greenLabel">+{amount}</span> : <span className="redLabel">-{amount}</span>
}
