import React from "react";
import "./PortfolioPanel.css"
import {useSelector} from "react-redux";
import {toEur} from "../../utils/tools";

export const PortfolioPrices = () => {
  const rates = useSelector(store => store.base.rates);

  return (
    <>
      <div>
        <div className="portfolioValueCation">Prices</div>
        <div className="flexResponsive">
          <div className="portfolioPanelBox">
            <div className="portfolioCryptoBox portfolioPPC">
              <div className="flexResponsive">
                <div className="whiteIcon"/>
                <div>
                  <div className="portfolioValue">{rates && toEur(rates.PPC.eurRate)}</div>
                  <div>PPC</div>
                </div>
              </div>
            </div>
            <div className="portfolioCryptoBox portfolioVCT">
              <div className="flexResponsive">
                <div className="whiteIcon"/>
                <div>
                  <div className="portfolioValue">{rates && toEur(0.4366)}</div>
                  <div>VCT</div>
                </div>
              </div>
            </div>

          </div>
          <div className="portfolioPanelBox">
            <div className="portfolioCryptoBox portfolioGOV">
              <div className="flexResponsive">
                <div className="whiteIcon"/>
                <div>
                  <div className="portfolioValue">{rates && toEur(1)}</div>
                  <div>GOV</div>
                </div>
              </div>
            </div>
            <div className="portfolioCryptoBox portfolioTBA">
              <div className="flexResponsive">
                <div className="whiteIcon"/>
                <div>
                  <div className="portfolioValue">{rates && toEur(0)}</div>
                  <div>TBA</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}