import React from "react";
import {withStyles} from "@material-ui/core/styles";
import {Button} from "@material-ui/core";

export const RedButton = withStyles(() => ({
  root: {
    color: "white",
    backgroundColor: "#FF6466",
    '&:hover': {
      backgroundColor: "#FF6466",
    },
    borderRadius: 8,
    minWidth: 150,
    height: 45
  },
}))(Button);