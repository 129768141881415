import React from "react";
import {useSelector} from "react-redux";
import {Email, Phone, Room} from "@material-ui/icons";

export const PortfolioPanel = ({rightComponent}) => {
  const user = useSelector(store => store.base.user);

  return (
    <>
      <div className="box flexResponsive">
        <div className="portfolioPanelBox" style={{width: "100%", borderRight: "1px solid #EDEDED"}}>
          <div className="flexResponsive">
            <div className="avatar"/>
            <div>
              <h1>{user && user.username}</h1>
              <div className="colorGray" style={{marginTop: 5}}>
                <div className="flexResponsive centerVertical" style={{fontWeight: 600}}>
                  <Room size="small"/>
                  <div>{user && user.countryName}</div>
                </div>
                <div style={{marginTop: 40}}>
                  Joined {user && user.createdAt}
                </div>
              </div>
            </div>
          </div>
          <div className="flexResponsive centerVertical" style={{marginTop: 40}}>
            <table>
              <thead>
              </thead>
              <tbody>
              <tr>
                {user && user.phone && (<td>Phone</td>)}
                <td>Email</td>
              </tr>
              <tr>
                {user && user.phone && (
                  <td>
                    <div className="flexResponsive centerVertical">
                      <div className="portfolioIcon">
                        {<Phone size="small"/>}
                      </div>
                      <div style={{fontWeight: 600}}>{user && user.phone}</div>
                    </div>
                  </td>
                )}
                <td>
                  <div className="flexResponsive centerVertical">
                    <div className="portfolioIcon">
                      {<Email size="small"/>}
                    </div>
                    <div style={{fontWeight: 600}}>{user && user.email}</div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="portfolioPanelBox" style={{width: "100%", marginLeft: 15}}>
          {rightComponent}
        </div>
      </div>
    </>
  )
}