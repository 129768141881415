import React from "react";
import {PortfolioPanel} from "../../components/PortfolioPanel/PortfolioPanel";
import {PortfolioValue} from "../../components/PortfolioPanel/PortfolioValue";
import {PortfolioMobile} from "./PortfolioMobile";
import {isMobile} from "../../utils/checkDevice";

export const Portfolio = () => {
  return (
    <>
      {isMobile() ?
        <PortfolioMobile/> :
        <PortfolioPanel rightComponent={<PortfolioValue/>}/>
      }
    </>
  )
}