import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import "../../index.css";
import "./Prices.css";
import {toEur} from "../../utils/tools";
import {useHistory} from "react-router-dom";
import {BigOrangeButton} from "../../components/LDButton/BigOrangeButton";
import {notification} from "../../components/Notification";
import {Modal} from "@material-ui/core";
import {ArrowDownward, ArrowUpward} from "@material-ui/icons";


export const PricesDetailsMobile = () => {
  const history = useHistory();
  const wallet = useSelector(store => store.base.wallet)
  const rates = useSelector(store => store.base.rates)
  const token = new URLSearchParams(history.location.search).get('token');
  const [eurRate, setEurRate] = useState(0);
  const [change24h, setChange24h] = useState(0);
  const [highPrice, setHighPrice] = useState(0);
  const [lowPrice, setLowPrice] = useState(0);
  const [about, setAbout] = useState();
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    if (token === "PPC") {
      setOpenModal(true);
    } else {
      notification.warning("Trading " + token + " tokens is not available now");
    }
};
const handleCloseModal = () => {
  setOpenModal(false);
};

const handleRedirect = (type) => {
  setOpenModal(false);
  history.push('/trade?token=' + token + '&type=' + type);
}

const modalBody = (
  <div className={'modal'}>
    <form>
      <div className="modalHeader">Choose sell or buy</div>
      <div className={'modalBody'}>
        <div className="pricesModalButtonContainer">
          <div style={{textAlign: "center"}} onClick={() => handleRedirect("Sell")}>
            <div>
              <div className="iconBuySell">
                <ArrowUpward/>
              </div>
            </div>
            <div style={{marginTop: 5}}>Sell</div>
          </div>
          <div style={{textAlign: "center"}} onClick={() => {
            handleRedirect("Buy")
          }}>
            <div>
              <div className="iconBuySell">
                <ArrowDownward/>
              </div>
            </div>
            <div style={{marginTop: 5}}>Buy</div>
          </div>
        </div>
      </div>
      <div className={'buttonContainer'}>
        <div className="cancelLink" onClick={handleCloseModal}>Cancel</div>
      </div>
    </form>
  </div>
);


useEffect(() => {
  if (rates && token) {
    switch (token) {
      case "PPC":
        setEurRate(rates.PPC.eurRate)
        setChange24h(rates.PPC.change24h)
        setHighPrice(rates.PPC.high24h)
        setLowPrice(rates.PPC.low24h)
        setAbout(
          "Pool Participating Certificate is the way our community\n" +
          "shares its profits with our members. To own PPC/s gives\n" +
          "you a return of 75% of the netprofits our\n" +
          "community makes. We stay together and we earn together."
        )
        break;
      case "GOV":
        setEurRate(1)
        break;
      case "VCT":
        setEurRate(0.4366)
        break;
    }
  }
}, [rates, token])

return (
  <>
    <div className="mobileContent">
      <div style={{textAlign: "center", fontSize: 17, fontWeight: 600}}>Details</div>
      <div className="mobileTokenInfo">
        <div style={{display: "flex", alignItems: "center"}}>
          <div className="mobileCurrencyIcon"/>
          <div style={{fontSize: 22}}>{token && token}</div>
        </div>
        <div style={{display: "flex", marginTop: 25, justifyContent: "space-between"}}>
          <div>
            <div style={{fontSize: 22}}>{toEur(eurRate)}</div>
            <div style={{fontSize: 12, marginTop: 5}}>{toEur(change24h)}</div>
          </div>
          <div style={{display: "flex"}}>
            <div className="mobileTokenHigh">
              <div style={{fontSize: 12, marginTop: 7}}>High price</div>
              <div style={{fontSize: 14, marginTop: 3}}>{toEur(highPrice)}</div>
            </div>
            <div className="mobileTokenLow">
              <div style={{fontSize: 12, marginTop: 7}}>Low price</div>
              <div style={{fontSize: 14, marginTop: 3}}>{toEur(lowPrice)}</div>
            </div>
          </div>
        </div>
      </div>
      <BigOrangeButton style={{marginTop: 20}} onClick={handleOpenModal}>Trade now</BigOrangeButton>
      <div className="mobileAboutToken">
        <h3 style={{marginBottom: 10}}>About {token && token}</h3>
        <div className="greyLabel">
          {about}
        </div>
      </div>
    </div>

    <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {modalBody}
    </Modal>

  </>
)
}