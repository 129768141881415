import React, {useState} from "react";
import {List, Modal} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {toEur} from "../../utils/tools";
import {BigOrangeButton} from "../../components/LDButton/BigOrangeButton";
import {notification} from "../../components/Notification";
import {FilledTextField} from "../../components/LDTextField/FilledTextField";
import {useSelector} from "react-redux";
import QRCode from 'qrcode.react';
import {client} from "../../services/client";
import {isMobile} from "../../utils/checkDevice";

export const Deposits = () => {
  const [openModalDeposit, setOpenModalDeposit] = useState();
  const [openModalDepositFinal, setOpenModalDepositFinal] = useState();
  const [paymentMethod, setPaymentMethod] = useState();
  const [amount, setAmount] = useState(0);
  const [cryptoAmount, setCryptoAmount] = useState(0);
  const [address, setAddress] = useState();
  const rates = useSelector(store => store.base.rates);

  const handleClickPaymentMethod = (e) => {
    setPaymentMethod(e)
    handleOpenModalDeposit(e)
  }

  const requestDepositAddress = async (e) => {
    e.preventDefault();
    try {
      const order = await client.payOrder({
        paymentMethod,
        pack: 18, //"DEPOSIT_CASH_WALLET",
        qty: 1,
        topupAmount: amount,
      });
      handleCloseModalDeposit();
      handleOpenModalDepositFinal();
      setAddress(order.cryptoAddress);
    } catch (error) {
      notification.warning(error.message);
    }
  }

  const confirmDeposit = (e) => {
    e.preventDefault();
    handleCloseModalDepositFinal();
  }

  const handleOpenModalDeposit = (type) => {
    setOpenModalDeposit(true)
  };
  const handleCloseModalDeposit = () => {
    setOpenModalDeposit(false);
  };
  const handleOpenModalDepositFinal = (type) => {
    setOpenModalDepositFinal(true)
  };
  const handleCloseModalDepositFinal = () => {
    setOpenModalDepositFinal(false);
    setAmount(0);
    setCryptoAmount(0);
  };

  const handleCancelClick = () => {
    setOpenModalDeposit(false);
    setAmount(0);
    setCryptoAmount(0);
  }

  const handleChangeAmount = (e) => {
    const amount = +e.target.value;
    let cryptoAmount;
    switch (paymentMethod) {
      case "BTC":
        cryptoAmount = (amount / rates.BTC.eurRate).toFixed(8)
        break;
      case "ETH":
        cryptoAmount = (amount / rates.ETH.eurRate).toFixed(8)
        break
      case "USDT":
        cryptoAmount = (amount / rates.USDT.eurRate).toFixed(2)
        break
    }
    setAmount(amount);
    setCryptoAmount(cryptoAmount)
  }

  const modalBodyDeposit = (
    <div className={'modal'}>
      <form onSubmit={requestDepositAddress}>
        <div className="modalHeader">Deposit</div>
        <div className={'modalBody'}>
          <div className="fieldLabel">Amount</div>
          <FilledTextField
            id="amount"
            name="amount"
            type="text"
            placeholder="0.00"
            autoComplete="off"
            variant="outlined"
            size="small"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            value={amount}
            onChange={handleChangeAmount}
          />
          <div className="fieldLabel">{paymentMethod} amount</div>
          <FilledTextField
            id="cryptoAmount"
            name="cryptoAmount"
            type="text"
            placeholder="0.00"
            autoComplete="off"
            variant="outlined"
            size="small"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            value={cryptoAmount}
          />
        </div>
        <div className={'buttonContainer'}>
          <BigOrangeButton
            type="submit"
          >
            Next step
          </BigOrangeButton>
          <div className="cancelLink" onClick={handleCancelClick}>Cancel</div>
        </div>
      </form>
    </div>
  );

  const modalBodyDepositFinal = (
    <div className={'modal'}>
      <form onSubmit={confirmDeposit}>
        <div className="modalHeader">Deposit final step</div>
        <div className={'modalBody'}>
          <div style={{textAlign: "center"}}>
            {address && (
              <>
                <QRCode style={{width: 200, height: 200}} value={address}/>
                <div className="depositField">
                  <div className="depositFieldCaption">Send {cryptoAmount} {paymentMethod}</div>
                  <div className="depositFieldBody">
                    Scan QR code or copy address below: <br/>
                    {address}
                  </div>
                </div>
              </>
            )}
          </div>

        </div>
        <div className={'buttonContainer'}>
          <BigOrangeButton
            type="submit"
          >
            DONE
          </BigOrangeButton>
          <div className="cancelLink" onClick={handleCloseModalDepositFinal}>Cancel</div>
        </div>
      </form>
    </div>
  );

  return (
    <>
      <div className={isMobile() ? "mobileContent" : "box ordersBox"}>
        {isMobile() ?
          <div style={{textAlign: "center", fontSize: 17, fontWeight: 600, marginBottom: 30}}>Deposit funds</div>
          :
          <h2>Deposits</h2>
        }
        <List>
          <>
            <ListItem button onClick={() => handleClickPaymentMethod("BTC")}>
              <ListItemIcon>
                <div className="btcIcon cryptoIcon"/>
              </ListItemIcon>
              <ListItemText primary={
                <>
                  <div className="depositsCryptoName">BTC</div>
                  <div className="depositsCryptoDescription">Transfer funds using Bitcoin from any wallet.</div>
                </>
              }/>
            </ListItem>
            <ListItem button onClick={() => handleClickPaymentMethod("USDT")}>
              <ListItemIcon>
                <div className="usdtIcon cryptoIcon"/>
              </ListItemIcon>
              <ListItemText primary={
                <>
                  <div className="depositsCryptoName">USDT</div>
                  <div className="depositsCryptoDescription">Transfer funds using USDT with ERC-20 blockchain.</div>
                </>
              }/>
            </ListItem>
            <ListItem button onClick={() => handleClickPaymentMethod("ETH")}>
              <ListItemIcon>
                <div className="ethIcon cryptoIcon"/>
              </ListItemIcon>
              <ListItemText primary={
                <>
                  <div className="depositsCryptoName">ETH</div>
                  <div className="depositsCryptoDescription">Transfer funds using ETH from any wallet.</div>
                </>
              }/>
            </ListItem>
          </>
        </List>
      </div>
      <Modal
        open={openModalDeposit}
        onClose={handleCloseModalDeposit}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBodyDeposit}
      </Modal>
      <Modal
        open={openModalDepositFinal}
        onClose={handleCloseModalDepositFinal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBodyDepositFinal}
      </Modal>
    </>
  )
}