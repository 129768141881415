import React from "react";
import {PortfolioPanel} from "../../components/PortfolioPanel/PortfolioPanel";
import {PortfolioPrices} from "../../components/PortfolioPanel/PortfolioPrices";
import {useSelector} from "react-redux";
import "../../index.css";
import {renderChange, toEur} from "../../utils/tools";
import {isMobile} from "../../utils/checkDevice";
import {PricesMobile} from "./PricesMobile";

export const Prices = () => {
  const rates = useSelector(store => store.base.rates)
  return (
    <>    {isMobile() ?
      <PricesMobile/> :
      <>
        <PortfolioPanel rightComponent={<PortfolioPrices/>}/>
        <div className="box" style={{marginTop: 20}}>
          <table className="table">
            <thead>
            <tr>
              <th>Asset</th>
              <th>Last price</th>
              <th>Change (24h)</th>
              <th>Volume (24h)</th>
              <th>High/Low</th>
              <th>Description</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                <div className="flexResponsive centerVertical">
                  <div className="ppcIcon"/>
                  PPC
                </div>
              </td>
              <td>
                {rates && rates.PPC && toEur(rates.PPC.eurRate)}
              </td>
              <td>
                {rates && rates.PPC && renderChange(rates.PPC.change24h)}
              </td>
              <td>
                {rates && rates.PPC && toEur(rates.PPC.volume24h)}
              </td>
              <td>
                {rates  && rates.PPC && (
                  <>
                    {/*<span className={"greenLabel"}>{toEur(rates.PPC.high24h)}</span>/*/}
                    {/*<span className="redLabel">{toEur(rates.PPC.low24h)}</span>*/}
                  </>
                )}
              </td>
              <td>
                Pool Participating Certificate is the way our community <br/>
                shares its profits with our members. To own PPC/s gives <br/>
                you a return of 75% of the netprofits our <br/>
                community makes. We stay together and we earn together.
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </>
    }
    </>
  )
}